@import "toastify-js/src/toastify.css";

html {
    /* Base RGB 462E74 https://paletton.com/ */
    --main-background-color: #8c7aae;
    --first-side-background-color: #665091;
    --second-side-background-color: #462e74;
    --warning-color: #d4c669;
    --error-color: #e23434;
    --error-border-color: #9b2121;

    /* <-- new values */
    --background-color: white;
    --counter-info-color: lightgrey;
    --dark-primary-color: #586ba5;
    --icon-color: white;
    --inverse-text-color: white;
    --header-color: #1c3a92;
    --light-primary-color: #929cb6;
    --negative-sum-color: rgba(182 73 73 100%);
    --notification-color: yellowgreen;
    --positive-sum-color: rgba(89 166 108 100%);
    --selected-task-item-color: coral;
    --task-item-color: cornflowerblue;
    --text-color: black;
}

@media (prefers-color-scheme: dark) {
    html {
        /* Base RGB 462E74 https://paletton.com/ */
        --main-background-color: #18063a;
        --first-side-background-color: #2c1657;
        --second-side-background-color: #462e74;
        --warning-color: #d4c669;

        /* <-- new values */
        --background-color: black;
        --counter-info-color: rgba(80 80 80 100%);
        --dark-primary-color: #525c76;
        --icon-color: darkgrey;
        --inverse-text-color: black;
        --header-color: #0c2a82;
        --negative-sum-color: darkred;
        --light-primary-color: #384b85;
        --notification-color: darkgreen;
        --positive-sum-color: darkgreen;
        --text-color: darkgrey;
    }
}

body {
    margin: 0;
    padding: 0;
}

input {
    background-color: var(--first-side-background-color);
    border: 0;
    border-bottom: 1px solid var(--text-color);
    color: var(--text-color);
    font-size: inherit;
    text-align: center;
    width: 90%;
}

.App {
    align-items: stretch;
    box-sizing: border-box;
    color: var(--text-color);
    display: flex;
    flex-direction: column;
    font-family: Verdana, Arial, sans-serif;
    font-size: 2vh;
    height: 100vh;
    justify-content: space-between;
    line-height: 2vh;
    width: 100vw;

    svg {
        height: 4.5vh;
        width: 4.5vh;
        color: var(--icon-color);
        fill: var(--icon-color);
        stroke: var(--icon-color);
    }

    .MeasureList {
        align-content: flex-start;
        background-color: var(--main-background-color);
        column-gap: 10%;
        display: flex;
        flex-grow: 1;
        flex-wrap: wrap;
        overflow: auto;
        padding: 5%;
        position: relative;
        row-gap: 5%;
    }

    .MeasureList__Item {
        align-items: center;
        background-color: var(--first-side-background-color);
        display: flex;
        height: 15%;
        justify-content: center;
        position: relative;
        width: 45%;

        > div {
            display: flex;
            flex-direction: column;
            justify-content: center;
            text-align: center;
            flex-grow: 0;
            margin-right: 6vh;
        }

        svg {
            min-width: 4.5vh;
        }

        &__Close {
            background-color: var(--second-side-background-color);
            border: 0;
            padding: 5px;
            position: absolute;
            right: 5px;
            top: 5px;

            svg {
                fill: transparent;
                height: 3vh;
                width: 3vh;
            }
        }

        &--active {
            background-color: var(--second-side-background-color);

            div {
                text-align: left;
            }
        }
    }

    .Copyright {
        bottom: 10%;
        position: absolute;
        right: 0;
    }

    aside {
        flex-grow: 0;
        height: 10%;

        &.Stats {
            background-color: var(--first-side-background-color);
            display: grid;
            grid-template-areas: "cumulative daily" "avg copyright";
            z-index: 2;

            & > div {
                align-items: center;
                display: flex;
            }
        }

        .Stats__Item__EstimatedLeave {
            margin-left: 0.5em;

            &::before {
                content: "> (";
            }

            &::after {
                content: ")";
            }
        }

        .Stats__CurrentTime {
            span {
                display: inline-block;
            }

            input {
                display: none;
                max-width: 150px;
            }

            &--edited {
                color: var(--warning-color);

                svg {
                    color: var(--warning-color);
                    fill: var(--warning-color);
                    stroke: var(--warning-color);
                }
            }

            &--editable {
                input {
                    display: inline-block;
                }

                span {
                    display: none;
                }
            }
        }
    }

    .Tooltip__Content {
        display: none;
        background-color: var(--second-side-background-color);
        border: 1px solid var(--main-background-color);
        padding: 1vh;
        font-size: 16px;
        color: var(--text-color);
        z-index: 50;
        min-width: 20vh;

        &--visible {
            display: block;
        }

        .Tooltip__Arrow {
            &::before {
                z-index: 49;
                content: "";
                position: absolute;
                width: 12px;
                height: 12px;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%) rotate(45deg);
                background: var(--second-side-background-color);
                border: 1px solid;
            }
        }

        &[data-popper-placement^="top"] > .Tooltip__Arrow {
            bottom: 0;

            &::before {
                border-color:
                    var(--second-side-background-color) var(--main-background-color)
                    var(--main-background-color) var(--second-side-background-color);
            }
        }

        &[data-popper-placement^="bottom"] > .Tooltip__Arrow {
            top: 0;

            &::before {
                border-color:
                    var(--main-background-color) var(--second-side-background-color)
                    var(--second-side-background-color) var(--main-background-color);
            }
        }
    }

    .Toaster {
        background: var(--error-color);
        border: 2px solid var(--error-border-color);
    }
}
